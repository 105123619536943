import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Header from "../components/header"
import Footer from "../components/footer"
import BlogPage from "../components/pages/blog-page"
import useJsSearch from "../utils/useJsSearch"



const PageBlog = ({ data, location, pageContext }) => {

  const post = data?.nodePage
  const english = data?.english
  const spanish = data?.spanish
  //const catalan = data?.catalan
  const blogPosts = data?.blogPosts
  const categories = data?.categories
  const pageInfo = data?.blogPosts?.pageInfo //blogPosts.pageInfo

  const translationPaths = {
   en: english?.path?.alias,
   es: spanish?.path?.alias,
 }

  const { search } = useJsSearch(blogPosts);

  const [blogs, setBlogs] = useState(data.blogPosts.nodes);
  const [searched, setSearched] = useState(false);
  const [initialQuery, setInitialQuery] = useState("");
  const [clearInput, setClearInput] = useState(false);
  //const [searchParams, setSearchParams] = useSearchParams();

  // Handles query state and prevents unnecessary rerendering
  useEffect(() => {
    const params = new URLSearchParams(location.search.slice(1));
    const q = clearInput ? params.delete("s") : params.get("s") ?? "";
    // Check if we have searched
    if (q !== initialQuery) {
      setSearched(false);
    }
    setInitialQuery(q);
    // If no query, reset blogs
    if (!q) {
      setBlogs(data.blogPosts.nodes);
      return;
    }
    // If query exists and we haven't searched yet, execute search
    if (q && !searched) {
      const results = search(q);
      setBlogs(results);
      setSearched(true);
    }
  }, [
    searched,
    data.blogPosts.nodes,
    search,
    location.search,
    initialQuery,
    clearInput
  ]);

  const refreshList = () => {
      setInitialQuery("")
      setClearInput(true)
      setSearched(false)
  }


  return (
    <Layout
      location={location}
      langcode={post?.langcode}
      translationPaths={translationPaths}
    >
    <Seo
        title={post?.title}
        lang={post?.langcode}
        description={post?.body?.summary}
        image={post?.relationships?.field_image?.image_style_uri?._653_432}
      />
      <Header
        lang={pageContext?.langcode}
        location={location}
       />
        <BlogPage
          post={post}
          lang={post?.langcode}
          blogPosts={blogs}
          bannerHeight="408px"
          categories={categories}
          pageInfo={pageInfo}
          initialQuery={initialQuery}
          refreshList={refreshList}
         />
      <Footer
        lang={pageContext?.langcode}
        location={location}
      />
    </Layout>
  )
}


export const pageQuery = graphql`
query PageBlog ($drupal_internal__nid: Int, $langcode: String!, $limit: Int!, $skip: Int!) {
    nodePage (
      drupal_internal__nid: {eq: $drupal_internal__nid},
      langcode:{eq: $langcode},
      ){
        title
        path{
          alias
        }
        body {
          processed
          summary
          value
        }
        relationships {
          field_image {
            image_style_uri {
              large
              medium
              thumbnail
              wide
              _294x192
              _541x359
              _653_432
            }
          }
        }
      }
      categories: allTaxonomyTermCategory {
        edges {
          node {
            name
            drupal_id
            drupal_internal__tid
            path {
              alias
            }
          }
        }
      }
      blogPosts: allNodeBlogPost(
        filter: { langcode: { eq: $langcode } }
        limit: $limit
        skip: $skip
        sort: {fields: created, order: DESC}
      )  {
        nodes {
          internal {
            type
           }
          langcode
          title
          created(formatString: "DD MMMM YYYY", locale: $langcode)
          body {
            summary
            value
          }
          path {
            alias
          }
          relationships {
            field_category{
              name
              path {
                alias
              }
            }
            field_image {
              image_style_uri {
                large
                medium
                thumbnail
                wide
                _294x192
                _541x359
                _653_432
              }
            }
          }
        }
        pageInfo {
          currentPage
          hasNextPage
          hasPreviousPage
          itemCount
          pageCount
          perPage
          totalCount
        }
      }

}
`

export default PageBlog
